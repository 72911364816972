import { Theme } from "@mui/material";
import { abstractTheme } from "./abstract";
import { calmTheme } from "./calm";
import { darkTheme } from "./dark";
import { gradientTheme } from "./gradient";
import { mosaicTheme } from "./mosaic";
import { localTheme } from "./upload";

export enum ThemeEnum {
  Abstract = "abstractTheme",
  Calm = "calmTheme",
  Dark = "darkTheme",
  Gradient = "gradientTheme",
  Mosaic = "mosaicTheme",
  Upload = "localTheme",
}

export function themeCreator(theme: string): Theme {
  return themeMap[theme];
}

const themeMap: { [key: string]: Theme } = {
  abstractTheme,
  calmTheme,
  darkTheme,
  gradientTheme,
  mosaicTheme,
  localTheme,
};
