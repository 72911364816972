import { createTheme } from "@mui/material";
import { baseTheme } from "./_themeDefaults";

export let localTheme = createTheme({
	palette: {
		mode: "dark",
		text: {
			primary: "#FFF", //Default text
			secondary: "#FFF", //Clock
		},
		background: {
			// paper: "#7d6d38",
			paper: "rgba(0,0,0,1)", //SearchBar
		},
		primary: {
			main: "#FFF",
		},
		secondary: {
			main: "#000",
		},
		error: {
			main: "#3294a6", //PlayPause
			light: "rgba(255,255,255,0.2)",
			dark: "#FFF",
		},
		success: {
			main: "#0F1217", //Pomodoro background
		},
	},
	components: {
		...baseTheme.components,
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					backgroundColor: "black",
					backgroundImage: localStorage.getItem("userBackground") ? `url(${localStorage.getItem("userBackground")})` : null,
					// backgroundImage: `url(${localStorage.getItem("userBackground")})`,
					"-webkit-background-size": "cover",
					"-moz-background-size": "cover",
					"-o-background-size": "cover",
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundAttachment: "fixed",
					backgroundPosition: "center",
					"-webkit-animation": "none",
					"-moz-animation": "none",
					"-o-animation": "none",
					animation: "none",
				},
			},
		},
	},
	typography: {
		...baseTheme.typography,
		subtitle1: {
			fontSize: "0.8rem",
			fontWeight: 400,
			color: "rgba(255,255,255,0.8)",
		},
	},
});
export default localTheme;
