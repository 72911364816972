import { createTheme } from "@mui/material";
import wallpaper from "../assets/themes/mosaic.svg";
import { baseTheme } from "./_themeDefaults";

export const mosaicTheme = createTheme({
	palette: {
		mode: "dark",
		text: {
			primary: "#FFF", //Default text
			secondary: "#FFF", //Clock
		},
		background: {
			paper: "#363642", //Search Bar
		},
		primary: {
			main: "#FFF",
		},
		secondary: {
			main: "rgba(0, 0, 0, 1)",
		},
		error: {
			main: "#b89a6b", //PlayPause
			light: "rgba(255,255,255,0.2)",
			dark: "#FFF",
		},
		success: {
			main: "#0F1217", //Pomodoro background
		},
	},
	components: {
		...baseTheme.components,
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					backgroundImage: `url(${wallpaper})`,
					"-webkit-background-size": "cover",
					"-moz-background-size": "cover",
					"-o-background-size": "cover",
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundAttachment: "fixed",
					backgroundPosition: "center",
					"-webkit-animation": "none",
					"-moz-animation": "none",
					"-o-animation": "none",
					animation: "none",
				},
			},
		},
	},
	typography: {
		...baseTheme.typography,
		subtitle1: {
			fontSize: "0.8rem",
			fontWeight: 400,
			textShadow: "none",
			color: "rgba(255,255,255,0.6)",
		},
	},
});
export default mosaicTheme;
