import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

type contentProps = {
	background?: string;
};

export const ContentBox: React.FC<contentProps> = props => {
	const useStyles = makeStyles({
		root: {
			background: props.background ? props.background : "transparent",
			height: "100%",
			borderRadius: "5px",
		},
	});
	const classes = useStyles();
	return <Box className={classes.root}>{props.children}</Box>;
};
export default ContentBox;
