import CallMadeIcon from "@mui/icons-material/CallMade";
import ClearIcon from "@mui/icons-material/Clear";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, Dialog, DialogContent, Divider, Grid, IconButton, Modal, TextField, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import useSound from "use-sound";
import { useTheme } from "../../ThemeProvider";
import bellSfx from "../../assets/sounds/bell.wav";
import useStickyState from "../../utils/useStickyState";

type pomodoroProps = {
	darkMode?: boolean;
};

export const PomodoroTimer: FC<pomodoroProps> = props => {
	const [isCounting, setIsCounting] = useStickyState("pomodoro_isCounting", false);
	const [isPause, setIsPause] = useStickyState("pomodoro_isPause", false);
	const [secondsLeft, setSecondsLeft] = useStickyState("pomodoro_secondsLeft", 2700);
	const [countdownDurationOption, setCountdownDurationOption] = useStickyState("pomodoro_countdownDuration", 45);
	const [pauseDurationOption, setPauseDurationOption] = useStickyState("pomodoro_pauseDuration", 5);
	const [ringBell] = useSound(bellSfx);
	const [open, setOpen] = useState(false);
	const { theme } = useTheme();

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const resetCountdown = (finished: boolean) => {
		setIsCounting(false);
		setIsPause(finished ? true : false);
		setSecondsLeft(finished ? pauseDurationOption * 60 : countdownDurationOption * 60);
	};

	const startCountdown = () => {
		setIsCounting(true);
	};

	const pauseCountdown = () => {
		setIsCounting(false);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			if (isCounting) {
				if (secondsLeft === 0) {
					ringBell();
					resetCountdown(isPause ? false : true);
				} else {
					setSecondsLeft(secondsLeft - 1);
				}
			}
		}, 1000);
		// Clear timeout if the component is unmounted
		return () => clearTimeout(timer);
	});

	const handleChangeCountdown = (event: any) => {
		const choice = parseInt(event.target.value);
		if (!isCounting && !isPause && choice) setSecondsLeft(choice * 60);
		setCountdownDurationOption(choice);
	};
	const handleChangePause = (event: any) => {
		const choice = parseInt(event.target.value);
		if (!isCounting && isPause && choice) setSecondsLeft(choice * 60);
		setPauseDurationOption(choice);
	};

	const timerBody = (type: "modal" | "timer") => (
		<Grid container justifyContent="center" alignItems="center" spacing={2} style={{ height: "100%" }}>
			<Grid item xs={12}>
				<Box pt={1}>
					<Typography
						variant={type === "modal" ? "h1" : "h2"}
						color={type !== "modal" && !props.darkMode ? "black" : "textPrimary"}
						aria-labelledby="Pomodoro timer countdown"
					>
						{Math.floor(secondsLeft / 60) < 10 ? "0" : ""}
						{Math.floor(secondsLeft / 60)}:{secondsLeft % 60 < 10 ? "0" : ""}
						{secondsLeft % 60}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<IconButton
					disableRipple
					sx={{
						backgroundColor: theme.palette.error.main,
					}}
					onClick={() => (isCounting ? pauseCountdown() : startCountdown())}
					aria-labelledby="Start/Stop button"
					aria-describedby="Start or stop timer countdown"
					size="large"
				>
					{!isCounting ? (
						<PlayArrowIcon
							sx={{
								color: theme.palette.error.dark,
								width: "60px",
								height: "60px",
							}}
						/>
					) : (
						<PauseIcon
							sx={{
								color: theme.palette.error.dark,
								width: "60px",
								height: "60px",
							}}
						/>
					)}
				</IconButton>
			</Grid>
			<Grid item xs={4} sm={4} lg={3}>
				<TextField
					id="outlined-number"
					label="Work"
					type="number"
					value={countdownDurationOption}
					onChange={handleChangeCountdown}
					inputProps={{
						min: "0",
						max: "999",
						step: "1",
					}}
					sx={{
						input: { color: type !== "modal" && !props.darkMode ? "black" : "white" },
						".MuiOutlinedInput-notchedOutline": {
							borderColor: type !== "modal" && !props.darkMode ? "black !important" : "white !important",
						},
						".MuiFormLabel-root": {
							color: type !== "modal" && !props.darkMode ? "black !important" : "white !important",
						},
					}}
					variant="outlined"
					aria-labelledby="Work duration select"
					aria-describedby="Change work session duration"
				/>
			</Grid>
			<Grid item xs={2.5} sm={2}>
				<IconButton
					disableRipple
					sx={{
						backgroundColor: type !== "modal" && !props.darkMode ? "black" : theme.palette.error.light,
					}}
					onClick={() => resetCountdown(false)}
					aria-labelledby="Clearbutton"
					aria-describedby="Clear current pomodoro timer"
					size="large"
				>
					<ClearIcon sx={{ color: theme.palette.text.primary, width: "30px", height: "30px" }} />
				</IconButton>
			</Grid>
			<Grid item xs={4} sm={4} lg={3}>
				<TextField
					id="outlined-number"
					label="Pause"
					type="number"
					value={pauseDurationOption}
					onChange={handleChangePause}
					inputProps={{
						min: "0",
						max: "999",
						step: "1",
					}}
					sx={{
						input: { color: type !== "modal" && !props.darkMode ? "black" : "white" },
						".MuiOutlinedInput-notchedOutline": {
							borderColor: type !== "modal" && !props.darkMode ? "black !important" : "white !important",
						},
						".MuiFormLabel-root": {
							color: type !== "modal" && !props.darkMode ? "black !important" : "white !important",
						},
					}}
					variant="outlined"
					aria-labelledby="Pause duration select"
					aria-describedby="Change pause duration"
				/>
			</Grid>
			<Grid item xs={5}>
				<Divider
					sx={{
						width: "100%",
						height: "3px",
						backgroundColor: type !== "modal" && !props.darkMode ? "black" : "white",
						display: !isPause ? "block" : "none",
					}}
				/>
				<Typography
					color={type !== "modal" && !props.darkMode ? "black" : "white"}
					variant="caption"
					style={{ display: !isPause ? "block" : "none" }}
					aria-labelledby="StudyPal pomodoro status"
					aria-describedby="Showing pomodoro status"
				>
					Work
				</Typography>
			</Grid>
			<Grid item xs={5}>
				<Divider
					sx={{
						width: "100%",
						height: "3px",
						backgroundColor: type !== "modal" && !props.darkMode ? "black" : "white",
						display: isPause ? "block" : "none",
					}}
				/>
				<Typography
					color={type !== "modal" && !props.darkMode ? "black" : "white"}
					variant="caption"
					style={{ display: isPause ? "block" : "none" }}
					aria-labelledby="StudyPal pomodoro status"
					aria-describedby="Showing pomodoro status"
				>
					Pause
				</Typography>
			</Grid>
		</Grid>
	);

	const modalBody = (
		<Dialog
			sx={{ height: "400px", width: "100%", background: theme.palette.success.main, textAlign: "center" }}
			onClose={handleClose}
			open={open}
			style={{ background: "rgba(0,0,0,1)", height: "100%" }}
		>
			<DialogContent style={{ height: "100%" }}>{timerBody("modal")}</DialogContent>
		</Dialog>
	);

	return (
		<Box
			pt={2}
			style={{ position: "relative" }}
			aria-labelledby="StudyPal pomodoro timer"
			aria-describedby="Timer for keeping track of your work/break balance"
		>
			<IconButton
				disableRipple
				sx={{
					backgroundColor: !props.darkMode ? "black" : theme.palette.error.light,
					position: "absolute",
					top: 8,
					right: 8,
				}}
				onClick={handleOpen}
				aria-labelledby="Expandbutton"
				aria-describedby="Open pomodoro timer in modal"
				size="large"
			>
				<CallMadeIcon sx={{ color: theme.palette.text.primary, width: "15px", height: "15px" }} />
			</IconButton>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="StudyPal Pomorodo timer"
				aria-describedby="Open the pomodoro timer in a modal"
			>
				{modalBody}
			</Modal>
			{timerBody("timer")}
		</Box>
	);
};
export default PomodoroTimer;
