import { createTheme } from "@mui/material";
import wallpaper from "../assets/themes/calm.jpg";
import { baseTheme } from "./_themeDefaults";

export const calmTheme = createTheme({
	palette: {
		mode: "dark",
		text: {
			primary: "#FFF", //Default text
			secondary: "#FFF", //Clock
		},
		background: {
			paper: "rgba(0,0,0,1)", //SearchBar
		},
		primary: {
			main: "#FFF",
		},
		secondary: {
			main: "rgba(0, 0, 0, 1)",
		},
		error: {
			// main: "#E03A3A", // Red
			// main: "#F9FC60", // Yellow
			// main: "#787D33", // Olive
			main: "#626D52", // PlayPause
			light: "rgba(255,255,255,0.2)",
			// dark: "#000",
			dark: "#FFF",
		},
		success: {
			main: "rgba(35, 97, 80, 1)", //Pomodoro background
		},
	},
	components: {
		...baseTheme.components,
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					backgroundImage: `url(${wallpaper})`,
					"-webkit-background-size": "cover",
					"-moz-background-size": "cover",
					"-o-background-size": "cover",
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundAttachment: "fixed",
					backgroundPosition: "center",
					"-webkit-animation": "none",
					"-moz-animation": "none",
					"-o-animation": "none",
					animation: "none",
				},
			},
		},
	},
	typography: baseTheme.typography,
	breakpoints: baseTheme.breakpoints,
});
export default calmTheme;
