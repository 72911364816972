import { createTheme } from "@mui/material";
import wallpaper from "../assets/themes/gradient.jpg";
import { baseTheme } from "./_themeDefaults";

export const gradientTheme = createTheme({
	palette: {
		mode: "dark",
		text: {
			primary: "#FFF", //Default text
			secondary: "#FFF", //Clock
		},
		background: {
			paper: "rgba(0,0,0,0.5)", //SearchBar
		},
		primary: {
			main: "#FFF",
		},
		secondary: {
			main: "rgba(0, 0, 0, 1)",
		},
		error: {
			// main: "#ff307c", //PlayPause
			// main: "#4AA1F8", //PlayPause
			// main: "#4ACAF0", //PlayPause
			main: "#FD756F", //PlayPause
			light: "rgba(255,255,255,0.2)",
			dark: "#FFF",
		},
		success: {
			main: "#0F1217", //Pomodoro background
		},
	},
	components: {
		...baseTheme.components,
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					backgroundImage: `url(${wallpaper})`,
					"-webkit-background-size": "cover",
					"-moz-background-size": "cover",
					"-o-background-size": "cover",
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundAttachment: "fixed",
					backgroundPosition: "center",
					"-webkit-animation": "AnimationName 30s ease infinite",
					"-moz-animation": "AnimationName 30s ease infinite",
					"-o-animation": "AnimationName 30s ease infinite",
					animation: "AnimationName 30s ease infinite",
				},
				"@-webkit-keyframes AnimationName": {
					"0%": { backgroundPosition: "0% 28%" },
					"50%": { backgroundPosition: "100% 73%" },
					"100%": { backgroundPosition: "0% 28%" },
				},
				"@-moz-keyframes AnimationName": {
					"0%": { backgroundPosition: "0% 28%" },
					"50%": { backgroundPosition: "100% 73%" },
					"100%": { backgroundPosition: "0% 28%" },
				},
				"@-o-keyframes AnimationName": {
					"0%": { backgroundPosition: "0% 28%" },
					"50%": { backgroundPosition: "100% 73%" },
					"100%": { backgroundPosition: "0% 28%" },
				},
				"@keyframes AnimationName": {
					"0%": { backgroundPosition: "0% 15%" },
					"50%": { backgroundPosition: "100% 90%" },
					"100%": { backgroundPosition: "0% 15%" },
				},
			},
		},
	},
	typography: {
		...baseTheme.typography,
		subtitle1: {
			fontSize: "0.8rem",
			fontWeight: 400,
			textShadow: "none",
			color: "rgba(255,255,255,0.8)",
		},
	},
	breakpoints: baseTheme.breakpoints,
});
export default gradientTheme;
