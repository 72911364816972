import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
	Box,
	Button,
	Grid,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
	createTheme,
	useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import { useTheme } from "../ThemeProvider";
import ContentBox from "../components/ContentBox/Contentbox";
import { AboutModal, PrivacyModal, StudyPalModal } from "../components/FooterModals/FooterModals";
import MarkdownNotes from "../components/MarkdownNotes/MarkdownNotes";
import PomodoroTimer from "../components/Pomodoro/PomodoroTimer";
import SearchBar from "../components/SearchBar/SearchBar";
import { ThemeEnum } from "../themes/_base";
import localTheme from "../themes/upload";
import useStickyState from "../utils/useStickyState";
import { Check, Close, Notes, Settings, Timer } from "@material-ui/icons";

export const DashboardView = () => {
	const [clockGrid, setClock] = useState<string>(new Date().toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" }));
	const [showUpload, setShowUpload] = useState(localStorage.getItem("appTheme") === "localTheme");
	const [activeWidgets, setActiveWidgets] = useStickyState("activeWidgets", [true, true]);
	const { theme, setTheme, _setTheme } = useTheme();
	const [darkMode, setDarkMode] = useState(window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches);
	const xs = useMediaQuery(theme.breakpoints.only("xs"));

	window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", event => {
		setDarkMode(event.matches);
	});

	function setUserDefinedTheme() {
		localStorage.setItem("appTheme", "localTheme");
		_setTheme(
			createTheme({
				...localTheme,
				components: {
					...localTheme.components,
					MuiCssBaseline: {
						styleOverrides: {
							body: {
								backgroundColor: "black",
								backgroundImage: localStorage.getItem("userBackground") ? `url(${localStorage.getItem("userBackground")})` : null,
								"-webkit-background-size": "cover",
								"-moz-background-size": "cover",
								"-o-background-size": "cover",
								backgroundSize: "cover",
								backgroundRepeat: "no-repeat",
								backgroundAttachment: "fixed",
								backgroundPosition: "center",
								"-webkit-animation": "none",
								"-moz-animation": "none",
								"-o-animation": "none",
								animation: "none",
							},
						},
					},
				},
			})
		);
	}

	function setBase64ImageInLocalStorage(img: any) {
		const reader = new FileReader();
		reader.readAsDataURL(img);

		reader.addEventListener("load", () => {
			if (reader.result && typeof reader.result === "string") {
				localStorage.setItem("userBackground", reader.result);
				setUserDefinedTheme();
			} else {
				console.log("Error while uploading!");
			}
		});
	}

	const useStyles = makeStyles({
		root: {
			height: "100vh",
			width: "100vw",
			textAlign: "center",
			overflow: "auto",
		},
		searchGrid: {
			heigth: "100px",
		},
		searchBar: {
			width: "100%",
			margin: "auto",
		},
		clockGrid: {
			padding: "40px",
		},
		containers: {
			height: "400px",
			marginBottom: "50px",
		},
	});
	const classes = useStyles();

	useEffect(() => {
		setInterval(function () {
			setClock(new Date().toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" }));
		}, 1000);
	}, []);

	const handleThemebuttonClick = (chosenTheme: ThemeEnum) => {
		setTheme(chosenTheme);
	};

	const themes: { name: string; enum: ThemeEnum }[] = [
		{ name: "Abstract", enum: ThemeEnum.Abstract },
		{ name: "Calm", enum: ThemeEnum.Calm },
		{ name: "Dark", enum: ThemeEnum.Dark },
		{ name: "Gradient", enum: ThemeEnum.Gradient },
		{ name: "Mosaic", enum: ThemeEnum.Mosaic },
		{ name: "User", enum: ThemeEnum.Upload },
	];

	// toggle button menu
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		const head = document.querySelector("head");
		const script = document.createElement("script");

		if (process.env.REACT_APP_LOCALHOST === "false" && head) {
			script.setAttribute("defer", "true");
			script.setAttribute("src", "https://analytics.mjntech.dev/script.js");
			script.setAttribute("data-website-id", "c3dccae5-1faf-42fe-9909-738d5027bb33");
			head.appendChild(script);
		}

		return () => {
			head?.removeChild(script);
		};
	}, []);

	return (
		<>
			<Grid container justifyContent="center" className={classes.root}>
				<Grid item xs={10} sm={7} md={6} xl={4} justifyContent="center" className={classes.searchGrid}>
					{/* Toggle menu */}
					<Box
						sx={{
							position: "fixed",
							top: 5,
							right: 5,
							zIndex: 100,
						}}
					>
						<Button id="menu_button" onClick={handleClick} sx={{ color: theme.palette.text.primary }}>
							<Settings style={{ color: theme.palette.text.primary, width: 20, height: 20 }} />
						</Button>
						<Menu
							id="basic-menu"
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								"aria-labelledby": "basic-button",
							}}
							sx={{
								"& .MuiPaper-root": {
									backgroundColor: darkMode ? "black" : "white",
								},
							}}
						>
							<MenuItem
								onClick={() => {
									const newState = [...activeWidgets];
									newState[0] = !newState[0];
									setActiveWidgets(newState);
								}}
							>
								<ListItemIcon>
									<Timer fontSize="small" style={{ color: darkMode ? "white" : "black" }} />
								</ListItemIcon>
								<ListItemText sx={{ color: darkMode ? "white" : "black" }}>Pomodoro</ListItemText>
								{/* <Typography variant="body2" color="text.secondary">
									⌘X
								</Typography> */}
								{activeWidgets[0] ? (
									<Check style={{ marginLeft: 12, color: darkMode ? "white" : "black" }} fontSize="small" />
								) : (
									<Close style={{ marginLeft: 12, color: "transparent" }} fontSize="small" />
								)}
							</MenuItem>
							<MenuItem
								onClick={() => {
									const newState = [...activeWidgets];
									newState[1] = !newState[1];
									setActiveWidgets(newState);
								}}
							>
								<ListItemIcon>
									<Notes fontSize="small" style={{ color: darkMode ? "white" : "black" }} />
								</ListItemIcon>
								<ListItemText sx={{ color: darkMode ? "white" : "black" }}>Notes</ListItemText>
								{activeWidgets[1] ? (
									<Check style={{ marginLeft: 12, color: darkMode ? "white" : "black" }} fontSize="small" />
								) : (
									<Close style={{ marginLeft: 12, color: "transparent" }} fontSize="small" />
								)}
							</MenuItem>
						</Menu>
					</Box>
					<Box pt={6}>
						<SearchBar />
						<Box mt={1}>
							{themes.map(theme => (
								<Button
									onClick={() => {
										handleThemebuttonClick(theme.enum);
										setShowUpload(theme.enum === ThemeEnum.Upload);
									}}
									aria-labelledby={"Change to StudyPal theme: " + theme.name}
								>
									{theme.name}
								</Button>
							))}
							{showUpload ? (
								<>
									<input
										accept="image/*"
										id="contained-button-file"
										multiple
										type="file"
										onChange={event => {
											if (event.target.files && event.target.files[0]) {
												setBase64ImageInLocalStorage(event.target.files[0]);
											}
										}}
										style={{ display: "none" }}
									/>
									<label htmlFor="contained-button-file">
										<Tooltip enterDelay={2000} title="Upload image (max 2MB)">
											<IconButton component="span" size="small" disableRipple sx={{ color: "white" }}>
												<FileUploadIcon sx={{ textShadow: "1px 1px 4px rgba(99, 99, 99, 0.8)" }} />
											</IconButton>
										</Tooltip>
									</label>
								</>
							) : null}
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} className={classes.clockGrid}>
					<Typography variant="h1" color="textSecondary" aria-labelledby="StudyPal clock">
						{clockGrid}
					</Typography>
				</Grid>
				<Grid container item justifyContent="center" xs={12} spacing={6} aria-labelledby="StudyPal pomodoro timer component">
					{activeWidgets[0] && (
						<Grid item xs={11.5} sm={8} md={5.5} lg={5} className={classes.containers}>
							<Box mb={0.6}>
								<Typography variant="h3" color="textPrimary">
									Pomodoro
								</Typography>
							</Box>
							<ContentBox background={darkMode ? "#0F1217" : "white"}>
								<PomodoroTimer darkMode={darkMode} />
							</ContentBox>
						</Grid>
					)}
					{activeWidgets[1] && (
						<Grid item xs={11.5} sm={8} md={5.5} lg={5} className={classes.containers} aria-labelledby="StudyPal notepad component">
							<Box mb={0.6}>
								<Typography variant="h3" color="textPrimary">
									Notes
								</Typography>
							</Box>
							<ContentBox>
								<MarkdownNotes />
							</ContentBox>
						</Grid>
					)}
					<Grid item xs={12} mb={xs ? 4 : 2}>
						<StudyPalModal />
						<PrivacyModal />
						<AboutModal />
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
export default DashboardView;
