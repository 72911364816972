import { TextField } from "@mui/material";
import { useState } from "react";
import { useTheme } from "../../ThemeProvider";
// import SearchBar from "material-ui-search-bar";
// import KeyboardEventHandler from "react-keyboard-event-handler";

export const Search = () => {
	const { theme } = useTheme();
	const [searchString, setSearchString] = useState<string>("");

	const handleSearch = () => {
		window.open("https://www.google.com/search?q=" + searchString, "_self"); // "_blank" for new tab
		setSearchString("");
	};

	return (
		<>
			<TextField
				variant="filled"
				fullWidth
				autoFocus
				placeholder="Search..."
				size="small"
				autoComplete="off"
				value={searchString}
				InputProps={{
					disableUnderline: true,
				}}
				inputProps={{
					style: {
						fontFamily: theme.typography.fontFamily,
						fontSize: 30,
						fontWeight: 400,
						padding: "4px 12px",
					},
				}}
				sx={{
					paddingBottom: 0,
					backgroundColor: "rgba(0,0,0,0.3)",
					borderRadius: 2,
					"& .MuiFilledInput-root": {
						borderRadius: 2,
					},
				}}
				InputLabelProps={{ style: { fontSize: 30 } }}
				onKeyDown={e => {
					if (e.key === "Enter") {
						handleSearch();
					}
				}}
				onChange={e => {
					setSearchString(e.target.value);
				}}
			/>
		</>
	);
};
export default Search;
