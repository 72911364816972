import { Box, Button, Dialog, DialogContent, DialogTitle, Fab, Modal, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import WideLogo from "../../assets/img/Wide_logo.svg";
import Portrait from "../../assets/img/me.jpg";
import { LocalCafe } from "@material-ui/icons";

export const StudyPalModal = () => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Button onClick={handleOpen} className={classes.button}>
				<Typography variant="subtitle1"> StudyPal </Typography>
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="StudyPal description"
				aria-describedby="Display information about the purpose of StudyPal"
			>
				<Dialog classes={{ paper: classes.modalContent }} onClose={handleClose} open={open} className={classes.modal}>
					<DialogContent>
						<Box pt={8} pb={6} px={3}>
							<img src={WideLogo} alt="StudyPal logo" aria-label="StudyPal logo"></img>
						</Box>
						<Typography variant="body1" className={classes.body} aria-label="Information about StudyPal">
							is a clean and modern dashboard for helping you focus more on your work, and less on finding the right tools. The purpose of
							the application is to have the productivity tools you need for the most optimal workflow while studying, all combined into one
							website. Write notes in{" "}
							<a href="https://www.markdownguide.org/getting-started/" target="_blank" rel="noreferrer">
								Markdown
							</a>
							, one of the most popular markup languages, which makes writing on the web fast and easy with live formatting on the go. Or
							utilize the{" "}
							<a
								href="https://www.forbes.com/sites/bryancollinseurope/2020/03/03/the-pomodoro-technique/?sh=204fd3903985"
								target="_blank"
								rel="noreferrer"
							>
								Pomodoro
							</a>{" "}
							timer for managing your time for the most efficient workflow. Pick a task, work during an interval of your choice, and take
							frequent, but shorter, breaks. When loading the page you have quick and easy access to the Google Search bar, and you can
							choose from a selection of themes.
						</Typography>
						<Box py={2}>
							<Typography variant="body1" className={classes.body}>
								PS: You can set this website as the default new tab page in your favorite browser for even quicker access!
							</Typography>
						</Box>
					</DialogContent>
				</Dialog>
			</Modal>
		</>
	);
};

export const PrivacyModal = () => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Button onClick={handleOpen} className={classes.button}>
				<Typography variant="subtitle1"> Privacy </Typography>
			</Button>
			<Modal open={open} onClose={handleClose} aria-labelledby="StudyPal privacymodal" aria-describedby="Display information about privacy">
				<Dialog classes={{ paper: classes.modalContent }} onClose={handleClose} open={open} className={classes.modal}>
					<DialogTitle>
						<Typography variant="h4" className={classes.title}>
							Privacy
						</Typography>
					</DialogTitle>
					<DialogContent className={classes.dialogContent}>
						<Typography
							variant="body1"
							className={classes.body}
							aria-label="Information about how privacy is handled in the application StudyPal"
						>
							This application utilizes local storage in your browser for storing information such as chosen theme, the remaining time left
							on the pomodoro timer and your notes written in the notepad. This is only stored locally on your own computer, and is not
							visible to the creator of this application. If you clear the browser cache, all the information will be safely removed.
						</Typography>
						<Box mt={1}>
							<Typography variant="body1" className={classes.body}>
								Below you will find a list of all the information stored right now.
							</Typography>
						</Box>
						<Box my={2} p={2} className={classes.localStorageContainer}>
							<Typography variant="body2" className={classes.body}>
								<text className={classes.localStorageKey} aria-label="StudyPal locally stored variable">
									appTheme:
								</text>{" "}
								"{localStorage.getItem("appTheme")}"
							</Typography>
							<Typography variant="body2" className={classes.body}>
								<text className={classes.localStorageKey} aria-label="StudyPal locally stored variable">
									userBackground:
								</text>{" "}
								{localStorage.getItem("userBackground") ? "[Base64 encoded image]" : "[No uploaded image]"}
							</Typography>
							<Typography variant="body2" className={classes.body}>
								<text className={classes.localStorageKey} aria-label="StudyPal locally stored variable">
									activeWidgets:
								</text>{" "}
								"{localStorage.getItem("activeWidgets")}"
							</Typography>
							<Typography variant="body2" className={classes.body}>
								<text className={classes.localStorageKey} aria-label="StudyPal locally stored variable">
									pomodoro_secondsLeft:
								</text>{" "}
								"{localStorage.getItem("pomodoro_secondsLeft")}"
							</Typography>
							<Typography variant="body2" className={classes.body}>
								<text className={classes.localStorageKey} aria-label="StudyPal locally stored variable">
									pomodoro_isCounting:
								</text>{" "}
								"{localStorage.getItem("pomodoro_isCounting")}"
							</Typography>
							<Typography variant="body2" className={classes.body}>
								<text className={classes.localStorageKey} aria-label="StudyPal locally stored variable">
									pomodoro_isPause:
								</text>{" "}
								"{localStorage.getItem("pomodoro_isPause")}"
							</Typography>
							<Typography variant="body2" className={classes.body}>
								<text className={classes.localStorageKey} aria-label="StudyPal locally stored variable">
									pomodoro_countdownDuration:
								</text>{" "}
								"{localStorage.getItem("pomodoro_countdownDuration")}"
							</Typography>
							<Typography variant="body2" className={classes.body}>
								<text className={classes.localStorageKey} aria-label="StudyPal locally stored variable">
									pomodoro_pauseDuration:
								</text>{" "}
								"{localStorage.getItem("pomodoro_pauseDurationOption")}"
							</Typography>
							<Typography variant="body2" className={classes.body}>
								<text className={classes.localStorageKey} aria-label="StudyPal locally stored variable">
									markdown:
								</text>{" "}
								"{localStorage.getItem("markdown")}"
							</Typography>
						</Box>
					</DialogContent>
				</Dialog>
			</Modal>
		</>
	);
};

export const AboutModal = () => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Button onClick={handleOpen} className={classes.button}>
				<Typography variant="subtitle1"> About </Typography>
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="About StudyPal"
				aria-describedby="Display information about the founder of StudyPal"
			>
				<Dialog classes={{ paper: classes.modalContent }} onClose={handleClose} open={open} className={classes.modal}>
					<DialogTitle>
						<Typography variant="h4" className={classes.title}>
							About
						</Typography>
						<Box pt={4} style={{ margin: "auto" }}>
							<img
								width="150"
								height="150"
								className={classes.profilePicture}
								src={Portrait}
								alt="The creator of StudyPal"
								aria-label="StudyPal-creator Martin Johannes Nilsen"
							/>
						</Box>
					</DialogTitle>
					<DialogContent className={classes.dialogContent}>
						<Typography variant="body1" className={classes.body}>
							StudyPal was created by Martin Johannes Nilsen, a Software Engineer with a M.Sc. in Computer Science from the University of
							Science and Technology in Trondheim, Norway. The purpose of the application is to have all the productivity tools you need for
							the most optimal workflow while studying, all combined into one website.
						</Typography>
						<Typography variant="body1" className={classes.body}>
							The website is free to use, while being hosted and maintained by me personally. If you want to support me, you can paypal me
							an amount of your choice.
						</Typography>
						<Box pt={2} style={{ textAlign: "center" }}>
							<Fab
								onClick={() => window.open("https://www.paypal.com/donate/?hosted_button_id=MJFHZZ2RAN7HQ", "_blank")}
								variant="extended"
								// style={{ backgroundColor: "#003892" }}
								style={{ backgroundColor: "#542A0C" }}
								aria-labelledby="StudyPal donatebutton"
								aria-describedby="Optional: Donate/pay for the application"
							>
								<LocalCafe style={{ color: "white", marginRight: 8 }} />
								<Typography variant="subtitle2" style={{ color: "white" }}>
									PayPal me a cacao
								</Typography>
							</Fab>
						</Box>
					</DialogContent>
				</Dialog>
			</Modal>
		</>
	);
};

const useStyles = makeStyles(theme => ({
	modalContent: {
		height: "600px",
		width: "600px",
		background: "white",
		textAlign: "center",
	},
	modal: {
		background: "rgba(0,0,0,0.5)",
		heigth: "100%",
	},
	button: {
		margin: "0 10px",
	},
	dialogContent: {
		textAlign: "left",
	},
	title: {
		color: "black",
	},
	body: {
		color: "black",
	},
	localStorageContainer: {
		width: "100%",
		backgroundColor: "#e6e6e6",
		borderRadius: "3px",
	},
	localStorageKey: {
		color: "red",
	},
	profilePicture: {
		borderRadius: "16px",
	},
}));
