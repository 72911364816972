import { createTheme } from "@mui/material";

// Create base theme
const defaultTheme = createTheme();
// https://mui.com/material-ui/customization/default-theme/
export const baseTheme = createTheme({
	typography: {
		fontFamily: "Gotham",
		h1: {
			fontSize: "7rem",
			fontWeight: 600,
			textShadow: "0px 0px 4px rgba(99, 99, 99, 0.5)",
			[defaultTheme.breakpoints.down("sm")]: {
				fontSize: "5.5rem",
			},
		},
		h2: {
			fontSize: "5.5rem",
			fontWeight: 600,
			textShadow: "0px 0px 4px rgba(99, 99, 99, 0.5)",
		},
		h3: {
			fontSize: "2rem",
			fontWeight: 600,
			textShadow: "0px 0px 4px rgba(99, 99, 99, 0.5)",
		},
		h4: {
			fontSize: "4rem",
			fontWeight: 600,
		},
		subtitle1: {
			fontSize: "0.8rem",
			fontWeight: 400,
			textShadow: "none",
			color: "rgba(255,255,255,0.5)",
		},
		subtitle2: {
			fontSize: "1rem",
			fontWeight: 600,
			textShadow: "none",
		},
		button: {
			fontSize: "1rem",
			fontWeight: 700,
			textShadow: "1px 1px 4px rgba(99, 99, 99, 0.8)",
			textTransform: "none",
		},
		body1: {
			fontSize: "1rem",
			fontWeight: 400,
		},
		body2: {
			fontSize: "1rem",
			fontWeight: 400,
		},
	},
	components: {
		// ??
		MuiCssBaseline: {
			styleOverrides: {
				body: {},
			},
		},

		// No default width on buttons
		MuiButton: {
			styleOverrides: {
				root: {
					minWidth: "0px",
				},
			},
		},

		// For tooltips
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: defaultTheme.palette.grey[800],
				},
			},
		},
	},
	breakpoints: defaultTheme.breakpoints,
	palette: defaultTheme.palette,
});
