import { CssBaseline } from "@mui/material";
import ThemeProvider from "./ThemeProvider";
import Dashboard from "./pages/Dashboard";

export const App = () => {
	return (
		<ThemeProvider>
			<CssBaseline />
			<Dashboard />
		</ThemeProvider>
	);
};
export default App;
